import deepmerge from "@node/deepmerge"

let locales = {}
const modules = import.meta.glob("@o-din-stock/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-stock/src/stock/store";
import menu from "@o-din-stock/src/stock/menu.json";

import StockItemIndex from "@o-din-stock/src/views/stock/items/Index.vue"
import StockItemForm from "@o-din-stock/src/views/stock/items/Form.vue"
import StockItemShow from "@o-din-stock/src/views/stock/items/Show.vue"

import StockCategoryIndex from "@o-din-stock/src/views/stock/category/Index.vue"
import StockCategoryUpdate from "@o-din-stock/src/views/stock/category/CategoryUpdate.vue"

import StockMaterialIndex from "@o-din-stock/src/views/stock/materials/Index.vue"
import StockMaterialForm from "@o-din-stock/src/views/stock/materials/Form.vue"
import StockMaterialShow from "@o-din-stock/src/views/stock/materials/Show.vue"

export default {
  routes: [
    { path: '/stock/items', component: StockItemIndex },
    { path: '/stock/items/new', component: StockItemForm },
    { path: '/stock/items/:id/edit', component: StockItemForm },
    { path: '/stock/items/:id', component: StockItemShow },
    { path: '/stock/categories', component: StockCategoryIndex },
    { path: '/stock/categories/:id/edit', component: StockCategoryUpdate },
    { path: '/stock/materials', component: StockMaterialIndex },
    { path: '/stock/materials/:id/edit', component: StockMaterialForm },
    { path: '/stock/materials/new', component: StockMaterialForm },
    { path: '/stock/materials/:id', component: StockMaterialShow }
  ],
  locales,
  store,
  menu: [menu]
}
