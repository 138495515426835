import adapter from "@o-din/src/adapter";

export default {
  director: {
    work_categories: {
      index: function(params = {}) {
        return adapter.get('api/v4/director/work_categories.json', { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/director/work_categories/${id}.json`)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/director/work_categories/${id}.json`, params)
      },
      create: function(params) {
        return adapter.post('api/v4/director/work_categories.json', params)
      }
    },
    dashboards: {
      show: function(params = {}) {
        return adapter.get("api/v4/director/dashboards.json", { params: params })
      }
    },
    issues: {
      index: function(params = {}) {
        return adapter.get("api/v4/director/issues.json", { params: params })
      },
      archive: function(params = {}) {
        return adapter.get("api/v4/director/issues/archive.json", { params: params })
      },
      show: function (id) {
        return adapter.get(`api/v4/director/issues/${id}.json`);
      },
      create: function (params) {
        return adapter.post('api/v4/director/issues.json', params)
      },
      update: function(id, params) {
        return adapter.patch("api/v4/director/issues/" + id + ".json", params)
      },
      destroy: function(params = {}) {
        return adapter.delete("api/v4/director/issues/" + params.id + ".json")
      },
      executors: function(params = {}) {
        return adapter.get(`api/v4/director/issues/executors_collection.json`, { params: params })
      },
    },
  }
}
