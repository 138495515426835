import adapter from "@o-din/src/adapter";

export default {
  round: {
    issues: {
      index: function(params = {}) {
        return adapter.get("api/v4/round/issues.json", { params: params })
      },
      fast_index: function(params = {}) {
        return adapter.get("api/v4/round/issues/fast.json", { params: params })
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/round/issues/${id}.json`, params)
      },
      create: function(params) {
        return adapter.post(`api/v4/round/issues.json`, params)
      },
      archive: function(params = {}) {
        return adapter.get("api/v4/round/issues/archive.json", { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/round/issues/${id}.json`)
      },
      executors: function(params = {}) {
        return adapter.get(`api/v4/round/issues/executors_collection.json`, { params: params })
      },
    },
    checklists: {
      index: function(params = {}) {
        return adapter.get("api/v4/round/checklists.json", { params: params })
      },
      get: function(id) {
        return adapter.get(`api/v4/round/checklists/${id}.json`)
      },
      destroy: function(params = {}) {
        return adapter.delete(`api/v4/round/checklists/${id}.json`)
      },
      create: function(params) {
        return adapter.post('api/v4/round/checklists.json', params )
      },
      update: function(id, form) {
        return adapter.patch(`api/v4/round/checklists/${id}.json`, form)
      }
    },
    work_plans: {
      index: function(params = {}) {
        return adapter.get("api/v4/round/work_plans.json", { params: params })
      },
      get: function(id) {
        return adapter.get(`api/v4/round/work_plans/${id}.json`)
      },
      destroy: function(params = {}) {
        return adapter.delete(`api/v4/round/work_plans/${id}.json`)
      },
      create: function(params) {
        return adapter.post('api/v4/round/work_plans.json', params )
      },
      update: function(id, form) {
        return adapter.patch(`api/v4/round/work_plans/${id}.json`, form)
      }
    },
    works: {
      index: function(params = {}) {
        return adapter.get("api/v4/round/works.json", { params: params })
      },
      get: function(id) {
        return adapter.get(`api/v4/round/works/${id}.json`)
      },
      destroy: function(params = {}) {
        return adapter.delete(`api/v4/round/works/${id}.json`)
      },
      create: function(params) {
        return adapter.post('api/v4/round/works.json', params )
      },
      update: function(id, form) {
        return adapter.patch(`api/v4/round/works/${id}.json`, form)
      }
    },
    issue_checklist_elements: {
      update: function(id, params) {
        return adapter.patch(`/api/v4/round/issue_checklist_elements/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/round/issue_checklist_elements/${id}.json`)
      }
    },
    tours: {
      create: function(params) {
        return adapter.post('api/v4/round/tours.json', params )
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/round/tours/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/round/tours/${id}.json`)
      }
    }
  }
}
