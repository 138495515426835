import deepmerge from "@node/deepmerge";

let locales = {};
const modules = import.meta.glob("@o-din-rounds/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-rounds/src/rounds/store.js";
import menu from "@o-din-rounds/src/rounds/menu.json";

import IssuesArchive from "@o-din-rounds/src/views/rounds/issues/IssuesArchive.vue"
import IssuesIndex from "@o-din-rounds/src/views/rounds/issues/IssuesIndex.vue"
import IssueShow from "@o-din-rounds/src/views/rounds/issues/Show.vue"
import ReportByExecutor from "@o-din-rounds/src/views/rounds/issues/ReportByExecutor.vue"

import ChecklistIndex from "@o-din-rounds/src/views/rounds/checklists/Index.vue"
import ChecklistForm from "@o-din-rounds/src/views/rounds/checklists/Form.vue"
import ChecklistShow from "@o-din-rounds/src/views/rounds/checklists/Show.vue"

import WorkPlanIndex from "@o-din-rounds/src/views/rounds/work_plans/Index.vue"
import WorkPlanForm from "@o-din-rounds/src/views/rounds/work_plans/Form.vue"

import WorkIndex from "@o-din-rounds/src/views/rounds/works/Index.vue"
import WorkForm from "@o-din-rounds/src/views/rounds/works/Form.vue"

export default {
  routes: [
    { path: '/round/issues/archive', component: IssuesArchive },
    { path: '/round/issues', component: IssuesIndex },
    { path: '/round/issues/report_by_executor', component: ReportByExecutor },
    { path: '/round/issues/:id', component: IssueShow },
    { path: '/round/checklists', component: ChecklistIndex },
    { path: '/round/checklists/:id/edit', component: ChecklistForm },
    { path: '/round/checklists/new', component: ChecklistForm },
    { path: '/round/checklists/:id', component: ChecklistShow },
    { path: '/round/work_plans', component: WorkPlanIndex },
    { path: '/round/work_plans/new', component: WorkPlanForm },
    { path: '/round/work_plans/:id/edit', component: WorkPlanForm },
    { path: '/round/works', component: WorkIndex },
    { path: '/round/works/new', component: WorkForm },
    { path: '/round/works/:id/edit', component: WorkForm },
  ],
  locales,
  store,
  menu: [menu]
}
