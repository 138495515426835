import deepmerge from "@node/deepmerge";

let locales = {};
const modules = import.meta.glob("@o-din-report/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-report/src/report/store.js";
import menu from "@o-din-report/src/report/menu.json";

import Maintenance from "@o-din-report/src/views/report/maintenance/Maintenance.vue";
import Ppr from "@o-din-report/src/views/report/ppr/Ppr.vue";
import Round from "@o-din-report/src/views/report/rounds/Round.vue";


export default {
  routes: [
    { path: '/report/maintenance', component: Maintenance },
    { path: '/report/ppr', component: Ppr },
    { path: '/report/round', component: Round }
  ],
  locales,
  store,
  menu: [menu]
}
