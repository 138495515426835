import deepmerge from "@node/deepmerge";

let locales = {};
const modules = import.meta.glob("@o-din-director/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-director/src/director/store.js";
import menu from "@o-din-director/src/director/menu.json";


export default {
  routes: [
    { path: '/director/issues/archive/', component: () => import("@o-din-director/src/views/director_issues/Archive.vue") },
    { path: '/director/issues/new', component: () => import("@o-din-director/src/views/director_issues/Form.vue") },
    { path: '/director/issues/:id/edit', component: () => import("@o-din-director/src/views/director_issues/Form.vue") },
    { path: '/director/issues/', component: () => import("@o-din-director/src/views/director_issues/Index.vue") },
    { path: '/director/issues/:id', component: () => import("@o-din-director/src/views/director_issues/Show.vue") },
    { path: '/director/dashboard', component: () => import("@o-din-director/src/views/director_reports/Index.vue") },
    { path: '/director/work_categories/new', component: () => import("@o-din-director/src/views/director_work_categories/Form.vue") },
    { path: '/director/work_categories/:id/edit', component: () => import("@o-din-director/src/views/director_work_categories/Form.vue") },
    { path: '/director/work_categories', component: () => import("@o-din-director/src/views/director_work_categories/Index.vue") },
  ],
  locales,
  store,
  menu: [menu]
}
