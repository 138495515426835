import adapter from "@o-din/src/adapter";

export default {
  audit: {
    templates: {
      index: function(params = {}) {
        return adapter.get('api/v4/audit/templates.json', { params: params })
      },
      get: function(id) {
        return adapter.get(`api/v4/audit/templates/${id}.json`)
      },
      create: function(params = {}) {
        return adapter.post('api/v4/audit/templates.json', params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      update: function(id, params = {}) {
        return adapter.patch(`api/v4/audit/templates/${id}.json`, params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      destroy: function(id, params = {}) {
        return adapter.delete(`api/v4/audit/templates/${id}.json`)
      },
      report: function(params = {}) {
        return adapter.get('api/v4/audit/templates/report.json')
      }
    },
    instances: {
      index: function(params = {}) {
        return adapter.get('api/v4/audit/instances.json', { params: params })
      },
      get: function(id) {
        return adapter.get(`api/v4/audit/instances/${id}.json`)
      },
      create: function(params) {
        return adapter.post('api/v4/audit/instances.json', params)
      },
      update: function(id, params = {}) {
        return adapter.patch(`api/v4/audit/instances/${id}.json`, params)
      }
    },
    attempts: {
      index: function(params = {}) {
        return adapter.get('api/v4/audit/attempts.json', { params: params })
      },
      create: function(params) {
        return adapter.post('api/v4/audit/attempts.json', params)
      },
    },
    questions: {
      update: function(id, params) {
        return adapter.patch(`api/v4/audit/questions/${id}.json`, params)
      }
    },
    notes: {
      index: function(params = {}) {
        return adapter.get('api/v4/audit/notes.json', { params: params })
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/audit/notes/${id}.json`, params)
      },
      create: function(params) {
        return adapter.post('api/v4/audit/notes.json', params)
      },
      destroy: function(id, params = {}) {
        return adapter.delete(`api/v4/audit/notes/${id}.json`)
      }
    }
  }
}
