import deepmerge from "@node/deepmerge";

let locales = {};
const modules = import.meta.glob("@o-din-audit/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-audit/src/audit/store";
import menu from "@o-din-audit/src/audit/menu.json";

// Templates
import AuditTemplatesIndex from "@o-din-audit/src/views/audit/templates/Index.vue";
import AuditTemplatesForm from "@o-din-audit/src/views/audit/templates/Form.vue";
import AuditTemplatesShow from "@o-din-audit/src/views/audit/templates/Show.vue";
// Instanses
import AuditInstancesIndex from "@o-din-audit/src/views/audit/instances/Index.vue";
import AuditInstancesShow from "@o-din-audit/src/views/audit/instances/Show.vue";
// Attempts
import AuditAttemptsIndex from "@o-din-audit/src/views/audit/attempts/Index.vue";
// Reports
import AuditReportIndex from "@o-din-audit/src/views/audit/attempts/ReportIndex.vue";

export default {
  routes: [
    {
      path: '/audit/',
      component: { template: '<router-view></router-view>' },
      children: [
        { path: 'templates', component: AuditTemplatesIndex },
        { path: 'attempts', component: AuditAttemptsIndex },
        { path: 'instances', component: AuditInstancesIndex },
        { path: 'reports', component: AuditReportIndex },
      ]
    },
    { path: '/audit/templates/new', component: AuditTemplatesForm },
    { path: '/audit/templates/:id/edit', component: AuditTemplatesForm },
    { path: '/audit/templates/:id', component: AuditTemplatesShow },
    { path: '/audit/instances/new', component: AuditTemplatesForm },
    { path: '/audit/instances/:id', component: AuditInstancesShow },

  ],
  locales,
  store,
  menu: [menu]
}
