import deepmerge from "@node/deepmerge";

let locales = {};
const modules = import.meta.glob("@o-din-ppr/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-ppr/src/ppr/store.js";
import menu from "@o-din-ppr/src/ppr/menu.json";

// Issues
import PprIssuesIndex from "@o-din-ppr/src/ppr/issues/Index.vue";
import PprIssuesShow from "@o-din-ppr/src/ppr/issues/Show.vue";
import PprIssuesArchive from "@o-din-ppr/src/ppr/issues/Archive.vue";
// Calendar
import PprCalendarIndex from "@o-din-ppr/src/ppr/calendar/Index.vue";
// Equipment
import PprEquipmentIndex from "@o-din-ppr/src/ppr/equipments/Index.vue";
import PprEquipmentWorkIndex from "@o-din-ppr/src/ppr/equipment_works/Index.vue";
import PprEquipmentForm from "@o-din-ppr/src/ppr/equipments/Form.vue";
import PprEquipmentQr from "@o-din-ppr/src/ppr/equipments/EquipmentQr.vue";
import PprEquipmentPrint from "@o-din-ppr/src/ppr/equipments/form/technological_map/print.vue";
// Works
import PprWorksIndex from "@o-din-ppr/src/ppr/works/Index.vue";
import PprWorkForm from "@o-din-ppr/src/ppr/works/Form.vue";
// GroupSystems
import PprGroupSystemsIndex from "@o-din-ppr/src/ppr/group_systems/Index.vue";
import PprGroupSystemsForm from "@o-din-ppr/src/ppr/group_systems/Form.vue";
// System
import PprSystemsIndex from "@o-din-ppr/src/ppr/systems/Index.vue";
import PprSystemsManage from "@o-din-ppr/src/ppr/systems/Manage.vue";
import PprSystemsForm from "@o-din-ppr/src/ppr/systems/Form.vue";
// Checklist
import ChecklistIndex from "@o-din-ppr/src/ppr/checklists/Index.vue";
import ChecklistShow from "@o-din-ppr/src/ppr/checklists/Show.vue";
import ChecklistItemForm from "@o-din-ppr/src/ppr/checklists/FormElement.vue";
// Disaggregation numbers
import PprDisaggregationIndex from "@o-din-ppr/src/ppr/disaggregation_numbers/Index.vue";
import PprDisaggregationForm from "@o-din-ppr/src/ppr/disaggregation_numbers/Form.vue";
// FrequencyWork
import FrequencyWorkIndex from "@o-din-ppr/src/ppr/frequency_works/Index.vue";
import FrequencyWorkForm from "@o-din-ppr/src/ppr/frequency_works/Form.vue";
// WorkAssignment
import WorkAssignmentIndex from "@o-din-ppr/src/ppr/work_assignment/Index.vue"

export default {
  routes: [
    { path: "/ppr/disaggregation_numbers", component: PprDisaggregationIndex },
    { path: "/ppr/disaggregation_numbers/new", component: PprDisaggregationForm },
    { path: "/ppr/disaggregation_numbers/:id/edit", component: PprDisaggregationForm },
    { path: "/ppr/issues/archive", component: PprIssuesArchive },
    { path: "/ppr/issues/:id", component: PprIssuesShow },
    { path: "/ppr/issues/", component: PprIssuesIndex },
    { path: "/ppr/checklists/", component: ChecklistIndex },
    { path: "/ppr/checklists/new", component: ChecklistItemForm },
    { path: "/ppr/checklists/:id/edit", component: ChecklistItemForm },
    { path: "/ppr/checklists/:id", component: ChecklistShow },
    { path: "/ppr/calendar", component: PprCalendarIndex },
    { path: "/ppr/group_systems", component: PprGroupSystemsIndex },
    { path: "/ppr/group_systems/new", component: PprGroupSystemsForm },
    { path: "/ppr/group_systems/:id/edit", component: PprGroupSystemsForm },
    { path: "/ppr/systems", component: PprSystemsIndex },
    { path: "/ppr/systems/new", component: PprSystemsForm },
    { path: "/ppr/systems/:id/manage", component: PprSystemsManage },
    { path: "/ppr/systems/:id/edit", component: PprSystemsForm },
    { path: "/ppr/equipments", component: PprEquipmentIndex },
    { path: "/ppr/equipment_works", component: PprEquipmentWorkIndex },
    { path: "/ppr/equipments/new", component: PprEquipmentForm },
    { path: "/ppr/equipments/:id/edit", component: PprEquipmentForm },
    { path: "/ppr/equipments/:id/print", component: PprEquipmentPrint },
    { path: "/ppr/equipments/print_qr", component: PprEquipmentQr },
    { path: '/ppr/works/new', component: PprWorkForm },
    { path: '/ppr/works/:id/edit', component: PprWorkForm },
    { path: '/ppr/works', component: PprWorksIndex },
    { path: '/ppr/frequency_works', component: FrequencyWorkIndex },
    { path: '/ppr/frequency_works/new', component: FrequencyWorkForm },
    { path: '/ppr/frequency_works/:id/edit', component: FrequencyWorkForm },
    { path: '/ppr/work_assignments', component: WorkAssignmentIndex }
  ],
  locales,
  store,
  menu: [menu]
};
