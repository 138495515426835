import adapter from "@o-din/src/adapter";

export default {
  report: {
    issues: {
      index: function (params = {}) {
        return adapter.get(`api/v4/report/issues.json`, { params: params })
      },
      ppr_active: function (params = {}) {
        return adapter.get(`api/v4/report/ppr_active.json`, { params: params })
      },
      ppr_archive: function (params = {}) {
        return adapter.get(`api/v4/report/ppr_archive.json`, { params: params })
      },
      maintenance_active: function (params = {}) {
        return adapter.get(`api/v4/report/maintenance_active.json`, { params: params })
      },
      maintenance_archive: function (params = {}) {
        return adapter.get(`api/v4/report/maintenance_archive.json`, { params: params })
      },
      round_active: function (params = {}) {
        return adapter.get(`api/v4/report/round_active.json`, { params: params })
      },
      round_archive: function (params = {}) {
        return adapter.get(`api/v4/report/round_archive.json`, { params: params })
      },
      group_systems: function(params = {}) {
        return adapter.get("/api/v4/report/group_systems.json", { params: params })
      },
      systems: function(params = {}) {
        return adapter.get("/api/v4/report/systems.json", { params: params })
      },
      ppr_excel: function(params = {}) {
        return adapter.get("/api/v4/report/issues/ppr_excel.xlsx", { params: params, responseType: 'blob' })
      }
    }
  }
}
