import { createApp } from '@o-din/app';

import Maintenance from '@o-din-maintenance/src/o-din-maintenance';
import Ppr from '@o-din-ppr/src/o-din-ppr';
import Audit from '@o-din-audit/src/o-din-audit';
import Rounds from '@o-din-rounds/src/o-din-rounds';
import Report from '@o-din-report/src/o-din-report';
import Stock from '@o-din-stock/src/o-din-stock';
import Director from '@o-din-director/src/o-din-director';

createApp('#app', [Audit, Maintenance, Rounds, Report, Ppr, Stock, Director]);
