import adapter from "@o-din/src/adapter";

export default {
  ppr: {
    issues: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/issues/fast_index.json", { params: params })
      },
      normo_hours: function(params = {}) {
        return adapter.get("/api/v4/ppr/issues/normo_hours.json", { params: params })
      },
      create: function(params) {
        return adapter.post("/api/v4/ppr/issues.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/issues/${id}.json`, params)
      },
      executors: function(params = {}) {
        return adapter.get(`api/v4/ppr/issues/executors_collection.json`, { params: params })
      },
      responsible: function(params = {}) {
        return adapter.get(`api/v4/ppr/issues/responsible_collection.json`, { params: params })
      },
      show: (id) => {
        return adapter.get(`api/v4/ppr/issues/${id}.json`)
      },
      pdf_report: (params = {}) => {
        return adapter.get(`/api/v4/ppr/issues/issues_export.pdf`, {
          params: params,
          responseType: "blob"
        })
      }
    },
    group_systems: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/group_systems.json", { params: params })
      },
      show: function (id) {
        return adapter.get(`api/v4/ppr/group_systems/${id}.json`)
      },
      create: function(params) {
        return adapter.post("/api/v4/ppr/group_systems.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/group_systems/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ppr/group_systems/${id}.json`)
      },
      normohours_by_facilities: function() {
        return adapter.get("api/v4/ppr/group_systems/normohours_by_facilities.json")
      },
      normohours: function(params) {
        return adapter.get("api/v4/ppr/group_systems/normohours.json", { params: params })
      },
    },
    systems: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/systems.json", { params: params })
      },
      show: function (id) {
        return adapter.get(`api/v4/ppr/systems/${id}.json`)
      },
      create: function(params) {
        return adapter.post("/api/v4/ppr/systems.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/systems/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ppr/systems/${id}.json`)
      },
      normohours: function(params) {
        return adapter.get("api/v4/ppr/systems/normohours.json", { params: params })
      },
    },
    equipments: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/equipments.json", { params: params })
      },
      full_index: function(params) {
        return adapter.get("/api/v4/ppr/equipments/full_index.json", { params: params })
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ppr/equipments/${id}.json`)
      },
      show: (id) => {
        return adapter.get(`api/v4/ppr/equipments/${id}.json`)
      },
      create: function(params) {
        return adapter.post("/api/v4/ppr/equipments.json", params)
      },
      generate: function(id, params) {
        return adapter.post(`/api/v4/ppr/equipments/${id}/generate_checklist.json`, params)
      },
      generate_checklist: function(id, params) {
        return adapter.post(`/api/v4/ppr/equipments/${id}/generate_ai_checklist.json`, params)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/equipments/${id}.json`, params)
      }
    },
    frequency_works: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/frequency_works.json", { params: params })
      },
      create: function(params) {
        return adapter.post("/api/v4/ppr/frequency_works.json", params)
      },
      show: function(id) {
        return adapter.get(`/api/v4/ppr/frequency_works/${id}.json`)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/frequency_works/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ppr/frequency_works/${id}.json`)
      }
    },
    checklists: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/checklists.json", { params: params })
      },
      create: function(params = {}) {
        return adapter.post("/api/v4/ppr/checklists.json", params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      update: function(id, params = {}) {
        return adapter.patch(`/api/v4/ppr/checklists/${id}.json`, params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      show: function(id) {
        return adapter.get(`/api/v4/ppr/checklists/${id}.json`)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/checklists/${id}.json`)
      }
    },
    issue_checklists: {
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/issue_checklists/${id}.json`, params)
      }
    },
    works: {
      index: function(params) {
        return adapter.get("/api/v4/ppr/works.json", { params: params })
      },
      create: function (params = {}) {
        return adapter.post("api/v4/ppr/works.json", params);
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/ppr/works/${id}.json`, params)
      },
      get: function (id) {
        return adapter.get(`api/v4/ppr/works/${id}.json`);
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ppr/works/${id}.json`)
      }
    },
    work_equipments: {
      index: function (params) {
        params.work_equipment = {"some": "0"};
        return adapter.patch("api/v4/ppr/work_equipments.json", params);
      },
      create: function (params) {
        return adapter.post("api/v4/ppr/work_equipments.json", params);
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/ppr/work_equipments/${id}.json`, params);
      },
      destroy: function (id) {
        return adapter.delete(`api/v4/ppr/work_equipments/${id}.json`);
      },
      pdf_calendar: (params = {}) => {
        return adapter.get(`/api/v4/ppr/work_equipments/pdf_calendar.pdf`, {
          params: params,
          responseType: "blob"
        })
      }
    },
    disaggregation_numbers: {
      index: function (params) {
        return adapter.get("api/v4/ppr/disaggregation_numbers.json", { params: params });
      },
      show: function (id) {
        return adapter.get(`api/v4/ppr/disaggregation_numbers/${id}.json`)
      },
      create: function (params) {
        return adapter.post("api/v4/ppr/disaggregation_numbers.json", params);
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/ppr/disaggregation_numbers/${id}.json`, params);
      }
    },
    work_assignments: {
      index: function(params) {
        return adapter.get(`api/v4/ppr/work_assignments.json?work_assignment[date]=${params.date}&work_assignment[user_id]=${params.user_id}`)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/ppr/work_assignments/${id}.json`, params)
      },
    },
    issue_work_assignments: {
      create: function(params) {
        return adapter.post("api/v4/ppr/issue_work_assignments.json", params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ppr/issue_work_assignments/${id}.json`)
      }
    }
  }
}
